import React from 'react';
//import Moment from 'react-moment';

class PointUI extends React.Component{



     createConnection = async (e) => {


                        const user_account = this.props.account;
             
                        
                       const result = await fetch('http://localhost:1982/api/create-connection' , {
                      
                        headers: { 'X-access-token' : localStorage.getItem('token'), 'user_account' : user_account } ,
                       
                                }).then((res) => res.json());
                    
                                const data = result;
                    
                                  if(data.status === 'ok'){
                                    
                                    console.log(data);

                                    return data;
                                 
                            }else{
                    
                                alert('Failed to create connection.');
                  
                     }
                  
                  }


               render(){ 
                
                console.log("./../media/images/"+this.props.image);
                
                return (
                    <div className='point-ui'>
                       <div className='opponent' style={this.props.win === 'lost' ? {background : '#900'} : {background : '#090'}}>
                        <div className='topline' align='center' >You {this.props.win} <b>{this.props.amount}</b>,  against</div> 
                        <div className='image-header' style={{background : `#eee url(${require("../media/images/"+this.props.image).default}) no-repeat center center` , backgroundSize : 'cover' }}></div>
                        <div className='name' >{this.props.name}</div>
                        <div className='province' >[{this.props.province}]</div>
                        </div>
                        <div className='details' >
                            {this.props.connected ? <a className='sendbtn' href={'/user/chat/'+this.props.connection_id} >Send message</a>: <><a className='sendbtn' onClick={this.createConnection}>Connect</a><div className='textarea'>Connecting enables you to chat with the other user.</div></> }
                            
                                </div>
                            </div>
                        
                    )}
}

export default PointUI