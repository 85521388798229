import $ from 'jquery';
let sdk;
let inline;
//const connectionPort = this.portUrl+'/';

class inzuzo{
  
    portUrl = 'https://www.inzuzo-a.com';

    constructor(){
        
          this.authenticated = true;
         
          
          let	closeButton 		  = document.querySelector(".close-button");
          let adminCloseButton 	= document.querySelector(".admin-close-button");

    }

    
   
   // unit changes header 
   async getPage(page){ //topup-page \ redeem-page

    

    const result = await fetch(this.portUrl+'/api/'+page, {
    
            headers: { 'X-access-token' : localStorage.getItem('token')} ,
   
            }).then((res) => res.json());

          const data = result;
          
          console.log(data);

          if(data.status === 'ok'){
                
                return data
              
          }else{

              alert('user not found');
          }


   }


   async sendSMS(cell , message){


    const result = await fetch(this.portUrl+'/api/send-sms' , {
    
            headers: { 'X-access-token' : localStorage.getItem('token') , 'cell' : cell  , 'message': message} ,
   
            }).then((res) => res.json());

          const data = result;

          if(data.status === 'ok'){

                return data
              
          }else{

              alert('user not found');
          }


   }

   async  unitchange(direction){

            
    const result = await fetch(this.portUrl+'/api/changeunit' , {
    
    headers: { 'X-access-token' : localStorage.getItem('token'),
                'direction' : direction} ,
   
            }).then((res) => res.json());

            const data = result;

        if(data.status === 'ok'){

            

                //alert(data.currentUnitindex);
                
                let tapeindex = data.currentUnitindex*40;

                $('#unit_tape').animate({left : "-"+tapeindex+'px'})

                //fun(data.name)
                
        }else{

            alert('user not found');
        }

    //console.log(data);

    } 

// login header 

   async getUserInfo(){

            
    const result = await fetch(this.portUrl+'/api/getuser' , {
    
            headers: { 'X-access-token' : localStorage.getItem('token')} ,
   
            }).then((res) => res.json());

        const data = result;

          if(data.status === 'ok'){

                return data
              
          }else{

              alert('user not found');
          }

    }
    
 //1. play page

 async processPayment(tokenObj , amount , id){

   // alert(1);

    const result = await fetch(this.portUrl+'/api/topup' , {

        headers: { 'X-access-token' : localStorage.getItem('token') , 'paymenttoken' : tokenObj , 'amount': amount , 'topup_id' : id} ,
               
                }).then((res) => res.json());
    
        const data = result;
    
      if(data.status === 'ok'){
    
               console.log(data);
               
               if(data.action){


               }
                               
      }else{
    
                alert('user not found');
      }
    
   // console.log(data);

}

    //1. play page
    async getItems(item){

    
      const result = await fetch(this.portUrl+'/api/'+item , {
  
          headers: { 'X-access-token' : localStorage.getItem('token')} ,
         
                  }).then((res) => res.json());
      
          const data = result;
      
        if(data.status === 'ok'){
      
                 return data;
               
                                 
        }else{
      
                  return null;
        }
      
}

    async getPoints(){

      const result = await fetch(this.portUrl+'/api/getpoints' , {
  
          headers: { 'X-access-token' : localStorage.getItem('token')} ,
         
                  }).then((res) => res.json());
      
          const data = result;
      
        if(data.status === 'ok'){
      
                 return data;
               
                                 
        }else{
      
                  return null;
        }
      
     // console.log(data);

  }
    async getPlayUnits(){

        const result = await fetch(this.portUrl+'/api/getplayunits' , {
    
            headers: { 'X-access-token' : localStorage.getItem('token')} ,
           
                    }).then((res) => res.json());
        
            const data = result;
        
          if(data.status === 'ok'){
        
                   return data;
                 
                                   
          }else{
        
                    return null;
          }
        
       // console.log(data);

    }

    logout(e){

      localStorage.removeItem('user');
      localStorage.removeItem('token');
      window.location.href = '/access/login'; 

      }

   showMenu(e){

    //$('#sideMenu-content').toggle+

    //alert('showmenu') 
      document.querySelector(".sideMenu").classList.toggle("sideMenu-show");
      document.querySelector(".sideMenu-content").classList.toggle("sideMenu-content-show");
                        

   }

   showPopup(heading , message){

    //$('#sideMenu-content').toggle+

    //alert('showmenu') 
      document.querySelector(".sideMenu").classList.toggle("sideMenu-show");
      document.querySelector(".sideMenu-content").classList.toggle("sideMenu-content-show");
                        

   }


                      


      popup( modalHeader , modalHtml , type, item_id ) {

    
      
        let modal 				    = document.querySelector(".popup");
        let	adminPopup			= document.querySelector(".admin-popup");
 
       switch( type){
       
           case 'video':
             
               modal.classList.toggle("show-popup");
               $('#popupHeader').html("<div class='error'>"+modalHeader+"</div>");
     var source = "<video width='100%' height='420' controls src='"+modalHtml+"' type='video/mp4' frameborder='0' allow='accelerometer; encrypted-media; gyroscope; picture-in-picture' allowfullscreen allow='autoplay'></video>";				
               $('#popupContent').html(source);
           
           break;
           break;
           case 'error':
             
               modal.classList.toggle("show-popup");
               $('#popupHeader').html("<div class='error'>"+modalHeader+"</div>");
               $('#popupContent').html("<div class='error-message'>"+modalHtml+"</div>");
           
           break;
           
           case 'success':
           
               modal.classList.toggle("show-popup");
               $('#popupHeader').html("<div class='success'>"+modalHeader+"</div>");
               $('#popupContent').html("<div class='success-message'>"+modalHtml+"</div>");
           break;
           
           case 'update':
           
               //modal.classList.toggle("show-popup");
               $('#popupHeader').html("<div class='success'>"+modalHeader+"</div>");
               $('#popupContent').html("<div class='success-meessage'>"+modalHtml+"</div>");
           break;
           
           case 'form':
               
               adminPopup.classList.toggle("show-admin-popup");
               $('#admin-popup-header').html("<div class='zwippi-basic-form-header'>"+modalHeader+"</div>");
               $('#admin-popup-content').html("<div class='zwippi-basic-form'>"+modalHtml+"</div>");
       
           break;
           
           case 'update-form':	
               $('#admin-popup-header').html(modalHeader);
               $('#admin-popup-content').html("<div class='zwippi-basic-form'>"+modalHtml+"</div>");
           break;
           
           case 'confirm':	
           
               adminPopup.classList.toggle("show-admin-popup");
               $('#admin-popup-header').html(modalHeader);
               $('#admin-popup-content').html("<div class='zwippi-basic-form'>"+modalHtml+"</div>");
           break;
           case 'login':	
           
               //modal.classList.toggle("login-popup");
               //$('#popupHeader').html(preloader);
               //$('#popupHeader').html(modalHtml);
               
           break;
           case 'logout':	
           
               //modal.classList.toggle("logout-popup");
               //$('#popupHeader').html(preloader);
               //$('#popupHeader').html(modalHtml);
             
           break;
           default:
           
               modal.classList.toggle("show-popup");
               $('#popupHeader').html(modalHeader);
               $('#popupContent').html(modalHtml);
               
           break;
             
     }
   }  

   async imageUpload(type , file){

      console.log(file)

   }

   async updateUser(v , item){

    if( v !== null && v.length > 0 ){


      $('#'+item+'_indicator').html('saving...');
      $('#'+item+'_indicator').fadeIn('fast');

    const result = await fetch(this.portUrl+'/api/update-profile' , {
    
      headers: { 'X-access-token' : localStorage.getItem('token') , 'item': item , 'value' : v} ,
     
              }).then((res) => res.json());
  
      const data = result;
  
    if(data.status === 'ok'){

      $('#'+item+'_indicator').html('done');
  
      $('#'+item+'_indicator').delay(1000).fadeOut('fast');
  
             return data;
           
                             
    }else{
  
              return null;
    }
  }
   }

   async showTyping(){}
   async clearThread(){}
   async sendMessage(){}

/*
   async windowOnClick(event) {

    let	adminPopup			= document.querySelector(".admin-popup");
    let modal 				    = document.querySelector(".popup");
  
    event.stopImmediatePropagation();
    switch(event.target){

      case document.querySelector(".popup") : 
      
          modal.classList.toggle("show-popup");
          $('#popupHeader').html('');
          $('#popupContent').html('');
      
      break;
      case document.querySelector(".close-button") :
        
        modal.classList.toggle("show-popup");
        $('#popupHeader').html('');
        $('#popupContent').html('');
      
      break;
      case document.querySelector(".admin-close-button") : 

                                          adminPopup.classList.toggle("show-admin-popup");
                                        $('#admin-popup-header').html("");
                                        $('#admin-popup-content').html("");
      break;
      case document.querySelector(".sideMenu") : 
      document.querySelector(".sideMenu").classList.toggle("sideMenu-show");
      document.querySelector(".sideMenu-content").classList.toggle("sideMenu-content-show");
      break;
      case document.querySelector(".sideMenu-close-button") : 
           
            document.querySelector(".sideMenu").classList.toggle("sideMenu-show");
            document.querySelector(".sideMenu-content").classList.toggle("sideMenu-content-show");  
        
        break;
      }

   if (event.target === document.querySelector(".popup")) {
   
        

   }else if (event.target === document.querySelector(".close-button")) {
     
        this.popup( '' , '' , 'success' , 0);

   
   }else if (event.target === document.querySelector(".admin-close-button")){
           
     adminPopup.classList.toggle("show-admin-popup");
     $('#admin-popup-header').html("");
     $('#admin-popup-content').html("");


   }else if(event.target === document.querySelector(".galary")){
       
     //galary( '' , '' );
       
       }else if (event.target === document.querySelector(".sideMenu")) {
           
         document.querySelector(".sideMenu").classList.toggle("sideMenu-show");
         document.querySelector(".sideMenu-content").classList.toggle("sideMenu-content-show");
         
     
     }else if (event.target === document.querySelector(".sideMenu-close-button")) {
           
       document.querySelector(".sideMenu").classList.toggle("sideMenu-show");
       document.querySelector(".sideMenu-content").classList.toggle("sideMenu-content-show");
       
   
     }else if (event.target === document.querySelector(".rightMenu")) {
           
       document.querySelector(".rightMenu").classList.toggle("rightMenu-show");
       document.querySelector(".cpanel-header").classList.toggle("cpanel-header-show");
       
   
   }else if (event.target === document.querySelector(".messanger-close-button")) {
           
     document.querySelector(".messanger").classList.toggle("messanger-show");
     document.querySelector(".messanger-content").classList.toggle("messanger-content-show");
 

     }else if (event.target === document.querySelector(".messanger")) {
           
                   document.querySelector(".messanger").classList.toggle("messanger-show");
                   document.querySelector(".messanger-content").classList.toggle("messanger-content-show");
               
       

     }
   }
   */
async topup( event ){

      event.preventDefault()
      
      // show preloader here

      let zpp = this.processPayment;  
      
      // Disable the button to prevent multiple clicks while processing
      var submitButton = document.getElementById('pay-button');
      var amountInCents = document.getElementById('amountSelect').value;
      
      var topup_id  = document.getElementById('amountSelect').options[document.getElementById("amountSelect").selectedIndex].id;
      //alert(amountInCents);

      submitButton.disabled = true;
      // This is the inline object we created earlier with the sdk
      let paymenttoken;

      inline.createToken()
      .then( function (result) {
        // Re-enable button now that request is complete
        // (i.e. on success, on error and when auth is cancelled)
        submitButton.disabled = false;
        if (result.error) {
          const errorMessage = result.error.message;
          errorMessage && alert("error occured1: " + errorMessage);
        } else {
          paymenttoken = result;
          
          //alert("card successfully tokenised: " + paymenttoken.id);
          
          zpp(paymenttoken.id , amountInCents , topup_id );
          //console.log(paymenttoken);
          
        }
      })
      .catch(function (error) {
        // Re-enable button now that request is complete
        submitButton.disabled = false;
       // alert("error occured2: " + error);
      });
    //});
    

    
  
  }
  async getPlays(){

    const result = await fetch(this.portUrl+'/api/getplays' , {
    
      headers: { 'X-access-token' : localStorage.getItem('token')} ,
     
              }).then((res) => res.json());
  
      const data = result;
  
    if(data.status === 'ok'){
  
                return data;        
                             
    }else{
  
              alert('cant hide coin found');
    }

  }
    async hideplay(side){

      const result = await fetch(this.portUrl+'/api/hidecoin' , {
    
        headers: { 'X-access-token' : localStorage.getItem('token'), 'coinside' : side } ,
       
                }).then((res) => res.json());
    
        const data = result;
    
      if(data.status === 'ok'){
    
               // console.log(data);
               
            
                               
      }else{
    
                alert('cant hide coin found');
      }

    }

    async hide(event){

     
        //var play_id = event.target.parentNode.parentNode.id;
     var action 	= event.target.className;
     //document.getElementById("myDIV").className
 
     switch(action){
     case 'unit_tab_down':
      
              this.unitchange('down') ;
     break;
    
     case 'unit_tab_up':
      
              this.unitchange('up') ;
     
     break;
     case 'messageIcon':
          
              alert('React to play : '+ action);
     
     break; 
    case 'heads':
      
              this.hideplay('heads');
    
    break;
    case 'tails':
        
          this.hideplay('tails');
    
    break;
    default: break;
     
          }
      }
   }
    
    export default new inzuzo();