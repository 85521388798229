import React from 'react';

class PublicHeader extends React.Component {

  constructor(props){
    
    super(props);

    this.state = {  sideMenu :    'sideMenu',
                    sideContent : 'sideMenu-content',
                    path : 'https://turbotrav.com/'
                    
              };
  
              this.popupRef = this.props.popupRef
      
            

  }



  closeMenu = (e) =>{

    
    this.setState({sideMenu : 'sideMenu' , sideContent : 'sideMenu-content' });
    
  }

  showMenu = (e) =>{

    this.setState({sideMenu : 'sideMenu-show' , sideContent : 'sideMenu-content-show' });
    
}
render(){
  return(
    <>
           <div className={this.state.sideMenu} onClick={this.closeMenu}>
        
        <div className={this.state.sideContent} id='sideMenu-content'>
            <a className="sideMenu-close-button" onClick={this.closeMenu}>&times;</a>
            <div id='sideMenuControlheader'>
              <lu>
            <li><a href={this.state.path}>Home</a></li>
            <li><a href={this.state.path+"about"}>Trav's story</a></li>
            <li><a href={this.state.path+"gallery"}>Gallery</a></li>
            <li><a href={this.state.path+"bookings"}>Bookings</a></li>
             </lu></div>
           
        </div>
    </div>
    <div className='header'>
    <div className='header-wrapper'>
    
    <div className='brand-box'>
      
     <a className='brand' href={this.state.path}></a>
     </div> 
     <div className='menu'>
       <a href={this.state.path}>home</a>
       <a href={this.state.path+'about'}>Trav's story</a>
       <a href={this.state.path+'gallery'}>Gallery</a>
       <a href={this.state.path+'bookings'}>Bookings</a>
       <button onClick={this.showMenu}  className='mobile-menu-btn'>Menu <i className='fas fa-bars'></i></button>
</div></div></div>
    </>
    )
}}

export default PublicHeader;