import React from 'react';
import { BrowserRouter as Router , Routes , Route  } from 'react-router-dom';

import './css/index.css';

import './css/fontawesome.css';
import './css/regular.css';
import './css/solid.css';
import './css/brands.css';

import './css/slick.css';
import './css/slick-theme.css';

import Popup from './pages/Popup';

// public pages

import Home from './pages/public/Home';
import About from './pages/public/About';
import Bookings from './pages/public/Bookings';
import Gallery from './pages/public/Gallery';

import PublicHeader from './pages/public/PublicHeader';
import PublicFooter from './pages/public/PublicFooter';


class App extends React.Component {
    
      
    constructor(props){

      super(props);
 
       this.state = {  auth : false,
                       totalusers : 0,
                       path : '/inzuzo/inzuzo/build/'  
                   };
       
                   this.popupRef = React.createRef();
 
       }




componentDidMount(){

   // this.checktoken();
  
}
render(){  
return (<>
<Router basename='/'>
  
<div className='wrapper'>
<Popup ref={this.popupRef} />
<PublicHeader />

<Routes>

{   <>  
      <Route path='/' exact element={ <Home />  } />
      <Route path='/about' exact element={ <Home />  } /> 
      <Route path='/gallery' exact element={ <Home />  } />
      <Route path='/bookings' exact element={ <Home />  } />
      <Route path="*" render={() => "404 Not found!"} />
  </>    
   }
   
 </Routes>
       

 </div>
 { /*<PublicFooter />*/ }
     </Router> </> 
      
    );  }
}

export default App;
