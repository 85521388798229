import React from 'react';
import inzuzo from './../../../classes/inzuzo';

class EmailForm extends React.Component {
 
    constructor(props){

     super(props);

               
                
                this.state = {  
                        activity : 'waiting',
                      };

                      this.imageRef = null; 
                      this.canvasRefPreview = React.createRef();

      }

      sendMessage = (event) => {

        event.preventDefault()
        
        this.setState({activity : 'awaiting'});

        let prom = inzuzo.getPage('sendMessage');
        //=  zwippi.getItems('history');// , 'history');
                  
        prom.then((data) => {
  
          console.log(data);

          this.setState({ activity : 'done' });
          
          setTimeout( ()=>{ 
      
            this.setState({ activity : 'normal' });
      
          }, 2 * 1000 );

        });
         
    
  }


render( ){

    switch(this.state.activity){
        case 'done':  return(   <div>Message sent successfully. :)</div> ); break;
        case 'awaiting':  return(   <div>Sending message...</div> ); break;
        default:  return(  <form className='public-form'>
        <div>  <div class='form-row'><label>Name</label><input type='text' placeholder='Name'/></div>
      <div class='form-row'><label>Email</label><input type='text' placeholder='Email'/></div>
      
      <div class='form-row'><label>Message</label>
      <textarea></textarea></div> </div>  
      <button onClick={this.sendMessage}>Send</button>
      </form> ); break;
        
}

}
 
}

export default EmailForm;