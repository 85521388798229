import React from 'react';

import $ from 'jquery';

import '../../slick/slick-theme.css';
import '../../slick/slick.css';

import PublicFooter from './PublicFooter';
import PublicHeader from './PublicHeader';


import slide1 from "../../img/slide_1.jpg";
import slide2 from "../../img/slide_2.jpg";
import slide3 from "../../img/slide_3.jpg";
import slide4 from "../../img/slide_4.jpg";

import { render } from '@testing-library/react';

import jquery from '../../slick/slick.js';

class Home extends React.Component {

  constructor(props){

    super(props);

    this.state = {  history : [],
                   
              };
  
    this.historyRef = React.createRef();

    let historyList = [];
    let slideIndex = 1;
    
  }

  componentDidMount(){

    $(document).ready(function(){
     $('.responsive').slick({ 
      prevArrow: $(".prevBtn"),
      nextArrow: $(".nextBtn"),
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
  autoplaySpeed: 3500,
      responsive: [
        {
          breakpoint: 1057,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 730,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });

  });

  }

  render(){

  return(
 <> <div className="responsive-wrapper"><div className="responsive">
 <div className='slider'><img src={slide1} /><p className='slider-content' style={{display: 'none'}}><div className='slide-text'><span className='bt1'>Welcome to</span> <span className='bt2'>TurboTrav</span><div className='summary'>Motivational Speaker</div></div></p></div>
 <div className='slider'><img src={slide2} /><p className='slider-content' style={{display: 'none'}}><div className='slide-text'><span className='bt1'>Shaping the future of businesses</span><div className='summary'>Reach your full potential</div></div></p></div>
 <div className='slider'><img src={slide3} /><p className='slider-content' style={{display: 'none'}}><div className='slide-text'><span className='bt1'>Accurate costing & pricing</span><div className='summary'>Unlock opportunities for our clients through big data</div></div></p></div>
 </div>
 <button className='prevBtn'><i className='fas fa-arrow-left'></i></button><button className='nextBtn'><i className='fas fa-arrow-right'></i></button></div>
  {/*
  <div className='content-wrapper' style={{display: 'none'}}>

<div className='content'>
      <div className='layoutBy2'>  <p><h3>Welcome to Turbo Trav</h3></p>
    
        <p>Travis Warwick Oliver is a motivational speaker who ... <br/> <a href='/about'>Read more</a></p>
    </div>
    <div className='layoutBy2_bottom'>  <p><h4>Events</h4></p><p><h4>Adventures</h4></p><p><h4>Motivational speaking</h4></p>
    
</div>
  </div></div>*/}
  
  </>
    )
}

}

export default Home;