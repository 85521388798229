import React, { useState } from 'react';

function App()
{

  let link = '';//'https://www.inzuzo-a.com'
  return(
    <div className='footer'>
    <div className='footer-wrapper'>
    <div className='part_1'>
          <h3>Social Media</h3><br/><a><i className='fab fa-twitter'></i></a> <a><i className='fab fa-facebook'></i></a> 
        <form><input type='email' placeholder='Request a meeting'/><button>Send</button></form>
        </div>
        <div className='part_2'><h3>Site Map</h3><div className='floatBreaker'></div>
        <div className='footermenu'><a href={link}>home</a>
       <a href={link+'/about'}>Travis story</a>
       <a href={link+'/gallery'}>Gallery</a>
       <a href={link+'/bookings'}>Bookings</a>
       </div></div>
       <div className='part_1'><div className='logo'></div><br/>© 2022 Inzuzo Analytics. All rights reserved. </div>
        </div>
        </div>
    )
}

export default App;