import React, { useState } from 'react';

import side_pic_1 from "../../img/page_2_art.jpg";

import gal_pic_1 from "../../img/gallery/img_1.jpg";
import gal_pic_2 from "../../img/gallery/img_2.jpg";
import gal_pic_3 from "../../img/gallery/img_3.jpg";
import gal_pic_4 from "../../img/gallery/img_4.jpg";
import gal_pic_5 from "../../img/gallery/img_5.jpg";
import gal_pic_6 from "../../img/gallery/img_6.jpg";
import gal_pic_7 from "../../img/gallery/img_7.jpg";
import gal_pic_8 from "../../img/gallery/img_8.jpg";
import gal_pic_9 from "../../img/gallery/img_9.jpg";
import gal_pic_10 from "../../img/gallery/img_10.jpg";
import gal_pic_11 from "../../img/gallery/img_11.jpg";
import gal_pic_12 from "../../img/gallery/img_12.jpg";


class Gallery extends React.Component {
    
        constructor(props){
    
            super(props);
    
            this.state = { playUnits : [],
                            playingUsers : 0,
                            data : [

                                { id: 1, imgSrc: gal_pic_1},
                                { id: 2, imgSrc: gal_pic_2},
                                { id: 3, imgSrc: gal_pic_3},
                                { id: 4, imgSrc: gal_pic_4},
                                { id: 5, imgSrc: gal_pic_5},
                                { id: 6, imgSrc: gal_pic_6},
                                { id: 7, imgSrc: gal_pic_7},
                                { id: 8, imgSrc: gal_pic_8},
                                { id: 9, imgSrc: gal_pic_9},
                                { id: 10, imgSrc: gal_pic_10},
                                { id: 11, imgSrc: gal_pic_11},
                                { id: 12, imgSrc: gal_pic_12}
                                        ]
                            
                        };
          
           
    
          /*  let data = [

                { id: 1, imgSrc: gal_pic_1},
                { id: 2, imgSrc: gal_pic_2},
                { id: 3, imgSrc: gal_pic_3},
                { id: 4, imgSrc: gal_pic_4},
                { id: 5, imgSrc: gal_pic_5},
                { id: 6, imgSrc: gal_pic_6},
                { id: 7, imgSrc: gal_pic_7},
                { id: 8, imgSrc: gal_pic_8},
                { id: 9, imgSrc: gal_pic_9},
                { id: 10, imgSrc: gal_pic_10},
                { id: 11, imgSrc: gal_pic_11},
                { id: 12, imgSrc: gal_pic_12}
                        ];
          */
            let imgList = [];

          }

render(){ 
    
    console.log(this.state.data);
    this.imgList    = this.state.data.map((i)=> <div className='pics' key={i.id} ><img src={i.imgSrc} style={{ width: '100%'}} /></div> )
    
    return(
        <>
  <div className="page-cover" style={{background : `#eee url(${side_pic_1}) no-repeat center center` , 'background-size' : 'cover' }}>
    <h1>Adventure gallery</h1>
  </div>
  <div className='content-wrapper'>
    <div className='gallery'> { this.imgList } </div>
    <div className='push'></div></div></>
 
 )
}
}
export default Gallery;