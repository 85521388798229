import React, { useState } from 'react';

import side_pic_1 from "../../img/page_2_art.jpg";
import EmailForm from './partials/EmailForm';


class ContactUs extends React.Component {

  constructor(props){

    super(props);

    this.state = {  history : [],
                   
              };
  
    this.historyRef = React.createRef();

    let historyList = [];
    let slideIndex = 1;
    
  }

  
render(){
  return(<><div className="page-cover" style={{background : `#eee url(${side_pic_1}) no-repeat center center` , 'background-size' : 'cover' }}><h1>Contacts & Bookings</h1>
  </div>
  <div className='content-wrapper'>
    <div className='content'>
  <div className='layoutBy2'>
    <h1>Contact us</h1>

  
    NZUZO ANALYTICS (PTY)LTD<br/>
   <p>
    <b>Tel:</b> 083 212 3029<br/>
    <b>email:</b> inf0@TurboTrav.com<br/>
    <b>Address:</b> 1119/7 Crescentwood Country Estate<br/>
Noordwyk<br/>
Midrand<br/>
</p>
</div>
 <div className='layoutBy2_bottom'>
  <EmailForm />
    </div></div><div className='push'></div>
</div>
</>
 )}
}

export default ContactUs;