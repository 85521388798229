import React, { useState } from 'react';

import side_pic_1 from "../../img/page_2_art.jpg";

function App(){

  return(<><div className="page-cover" style={{background : `#eee url(${side_pic_1}) no-repeat center center` , 'background-size' : 'cover' }}><h1>Travis' story</h1>
  </div>
  <div className='content-wrapper'>
    <div className='content'>
    <div className='layoutBy2' style={{'margin-bottom' : '5px'}}>       

    <div className='mobile_art' style={{background : `#eee url(${side_pic_1}) no-repeat center center` , 'background-size' : 'cover' }}></div>
<p>Travis Warwick Oliver is a motivational speaker who ...</p>
 </div>
    <div className='layoutBy2Art'> <h2>Travis' story</h2>
<p>Coming soon...</p></div>
  
</div><div className='push'></div></div></>
    )
}

export default App;