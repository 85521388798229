import React, { Component, useEffect, useState } from 'react';
//import jwt from 'jsonwebtoken';
//import Moment from 'react-moment';
//import zwippi from './../classes/zwippi';
import PointUI from './PointUI';
//import { data } from 'jquery';
//import PreloaderUi from './PreloaderUi';


class Popup extends Component {
//  function PlayToken(props){

    constructor(props){

     super(props);

      this.state = {  playbtns : 'cancel',
                      render : "",
                      isActive : "popup",
                      popup : 'popup',
                      reason : "reason",
                      reasonMessage: "",
                      header : '',
                      body : '',
                      admin_popup : 'admin-popup',
                      admin_header : '',
                      admin_body : ''
                      
                  };
      
                  this.popupRef = React.createRef();

      }
  
  

  changeCell(){

    alert('Cell change');

  }

  showPopup = ( data , type ) => {


      switch(type){

          case 'point':
         
              this.setState({ header : <PointUI connected={data.data.connected} connection_id={data.data.connection_id} account={data.data.account} image={data.data.userImage} name={data.data.name} province={data.data.province} win={data.win} amount={data.amount} timeAgo={data.data.timeAgo} />})
          
          break;
          case 'preloader':
         
            this.setState({ header : <PointUI image={data.image} name={data.name} province={data.province} win={data.win} amount={data.amount} timeAgo={data.timeAgo} />})
        
          break;
          case 'notification':
         
            this.setState({ header : data.header , body : data.message})
        
          break;
          case 'cell-change':
         
            this.setState({admin_header : data.header , admin_body : data.message})
        
          break;
          default: break;
      }    
      
    setTimeout( ()=>{ 

      if(type !== 'cell-change'){

        this.setState({ popup : 'show-popup' });

      }else{

        this.setState({ admin_popup : 'admin-show-popup' });

      }
    
    }, 100 );

  }

      hide = (e) => {
        
        if( e.target.className ==='close-button' || e.target.className === 'show-popup'){

               this.setState({header: '' , body : '' , popup : 'popup'});
        }
      
    }

    
    adminhide = (e) => {
        
      if( e.target.className ==='admin-close-button'){

             this.setState({admin_header: '' , admin_body : '' , admin_popup : 'admin-popup'});
      }
    
  }

    componentDidMount(){
    
} 

render( ){

  return (

<div>

<div className={this.state.popup} id='popup' onClick={this.hide}><div className='popup-content'>
  <span className='close-button'>&times;</span>
  <div className='floatBreaker'></div>
          <div id='popupHeader'>{this.state.header}</div>
          <div id='popupContent'>{this.state.body}</div>
  </div>
</div>

<div className={this.state.admin_popup}> <div className='admin-popup-content'>
  <span className='admin-close-button' onClick={this.adminhide}>&times;</span>
  <div className='floatBreaker'></div>
  <div style={{'border-top ': '1px solid #B00', 'margin-top': '10px'}}></div>
  <div id='admin-popup-header'>{this.state.admin_header}</div>
  <div id='admin-popup-content'>{this.state.admin_body}</div>
</div>
</div>
</div>)

}
 
}

export default Popup;